import moment from "moment";
import { Method } from "~/enums";
import { CodeDto, EquipDto, EventInfo, FaultTrendInfo, OperEquipStatus, OperEquipStatusByRegion, ProdByEngSource, ProdInfoStatus, ProdTrendsInfo, SearchParamDto, UserInfo } from "~/models/dto-types";
import tocRouteList from "@/utils/toc-route_list.json";
import { useUserStore } from "./userStore";
import { useCommonCodeStore } from "./commonCodeStore";

import ASTempData from './asTempData.json';

interface selectRouteForm{
  path: string,
  name: string,
  icon: string,
  child: Array<this>
}

//임시
interface ASList {
  idx             :number,
  cid             :string,
  siteName        :string,
  engSource       :string,
  engSourceName   :string,
  equipName       :string,
  equipAddr       :string,
  equipDetailAddr :string,
  dongCode        :string,
  prjKind         :string,
  prjRegYear      :string,
  ownerSeq        :number,
  equipStatus     :string,
  equipCompSeq    :string,
  ownerName       :string,
  ownerMobile     :string,
  cityCode        :string,
  sigunguCode     :string,
  companyName     :string,
  constructionDate:string,
  equipCapacity   :string,
  installPlanNumber:string,
  installConfirmMngNumber:string,
}

interface ASActionList{
  asRequestFormID     :number,
  asDate              :string,
  asStatus            :string
  asCompanyName       :string
  engSource           :string
  ownerName           :string
  equipAddr           :string
  equipDetailAddr     :string
  prjKind             :string

}

interface ASActionDetail{
  requestUserName     :string,
  requestUserMobile   :string,
  requestUserEmail    :string,
  ownerName           :string,
  ownerMobile         :string,
  usagePlan           :string,
  cid                 :string,
  equipAddr           :string,
  equipDetailAddr     :string,
  engSource           :string,
  prjKind             :string,
  prjRegYear          :string,
  buildingUsage       :string,
  companyName         :string,
  companyManagerName  :string,
  companyManagerMobile  :string,
  installPlanNumber     :string,
  installConfirmMngNumber :string,
  request                 :string,
  asCompanyName           :string,
  ascCompanyManager       :string,
  asCompanyManagerMobile  :string,
  codes                   :string,
  asCompleteDate          :string,
  faulty                  :string,
  asContent               :string,
  beforeASFiles           :File[],
  afterASFiles            :File[]
}


export const useTocStore = defineStore('tocStore', {
  state: () => ({
    dashboardProd: useLocalStorage("dashboardProd", {} as any),
    tocEquipList: useLocalStorage("tocEquipList", [] as EquipDto[]),
    defaultCity: useLocalStorage("defaultCity", '' as string | undefined),
    defaultSigungu: useLocalStorage("defaultSigungu", '' as String | undefined),
    selectCity: useLocalStorage("selectCity", '' as string),
    selectSigungu: useLocalStorage("selectSigungu" ,'' as string),
    tocRouteList: tocRouteList,
    tableViewPageNumber: 0,
    mainRoute: useLocalStorage("mainRoute", {} as selectRouteForm),
    subRouteList: useLocalStorage("subRouteList", [] as selectRouteForm[] | null),
    depth1Route: useLocalStorage("depth1Route", {} as selectRouteForm),
    depth2Route: useLocalStorage("depth2Route", null as selectRouteForm | null),
    depth3Route: useLocalStorage("depth3Route", null as selectRouteForm | null),
    //AS
    ASList: useLocalStorage("ASList", [] as ASList[] | null),
    selectASInfo: useLocalStorage("selectASInfo", {} as ASList | null),
    
    ASActionList: useLocalStorage("ASActionList", [] as ASActionList[] | null),
    selectASActionDetail: useLocalStorage("selectASServiceDetail", {} as ASActionDetail | null)
    // ASRequestList: useLocalStorage("ASRequestList", [] as )
  }),
  getters: {},
  actions: {
    setTableViewPageNumber(num: number){
      this.tableViewPageNumber = num;
    },
    resetTableViewPageNumber(){
      this.tableViewPageNumber = 0;
    },
    async getEquipList() {
			const res = await useCustomFetch<EquipDto[]>(`/api/v1/equips`, 
        { method: Method.GET, query: { pageNo: 1, pageSize: 5000 } });
			
      this.defaultCity = undefined;
      this.defaultSigungu = undefined;

      this.tocEquipList = res.data.value.content as EquipDto[];

      let equipCitys = this.tocEquipList.map(v => v.dongCode?.substring(0, 2) ?? null);
      let equipSigungus = this.tocEquipList.map(v => v.dongCode?.substring(2, 5) ?? null);

      equipCitys = [...new Set(equipCitys)];
      equipSigungus = [... new Set(equipSigungus)];

      if(equipCitys.length === 1){
        this.defaultCity = equipCitys[0] as string;
      }
      if(equipSigungus.length === 1){
        this.defaultSigungu === equipSigungus[0] as string;
      }

			return this.tocEquipList;
		},
    async getEquipListOnParam(
      param?:{
        pageNo: number,
        pageSize: number,
        cityCode?: string,
        sigunguCode?: string,
        dongCode?: string,
        prjKind?: string,
        buildingUsage?: string,

      }
    ) {
			const res = await useCustomFetch<EquipDto[]>(`/api/v1/equips`, 
        { method: Method.GET, query: { pageNo: 1, pageSize: 5000 } });
			
      this.defaultCity = undefined;
      this.defaultSigungu = undefined;

      this.tocEquipList = res.data.value.content as EquipDto[];

      let equipCitys = this.tocEquipList.map(v => v.dongCode?.substring(0, 2) ?? null);
      let equipSigungus = this.tocEquipList.map(v => v.dongCode?.substring(2, 5) ?? null);

      equipCitys = [...new Set(equipCitys)];
      equipSigungus = [... new Set(equipSigungus)];

      if(equipCitys.length === 1){
        this.defaultCity = equipCitys[0] as string;
      }
      if(equipSigungus.length === 1){
        this.defaultSigungu === equipSigungus[0] as string;
      }

			return this.tocEquipList;
		},
		async tocDashboardProdData(cityCode: string, sigunguCode: string) {
      // let city = this.defaultCity === '' ? cityCode : this.defaultCity
      // let sigungu = this.defaultSigungu === '' ? sigunguCode : this.defaultSigungu

			const prodRes = await useCustomFetch(`/api/v1/prod/overall`, { method: Method.GET});

      let result = prodRes.data.value as ProdInfoStatus;
   

			return result;
		},
    async tocDashboardfaultData(cityCode: string, sigunguCode: string, searchDateType: number){
      let city = this.defaultCity === '' ? cityCode : this.defaultCity
      let sigungu = this.defaultSigungu === '' ? sigunguCode : this.defaultSigungu
      let dateType = searchDateType ? searchDateType : 0;


      let startDate = moment().format('YYYY-MM-DD');
      let endDate = moment().format('YYYY-MM-DD');

      if(dateType === 0){
        startDate = moment().startOf('day').format('YYYY-MM-DD');
        endDate = moment().endOf('day').format('YYYY-MM-DD');
      } else if(dateType === 1){
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
      } else if(dateType === 2){
        startDate = moment().startOf('year').format('YYYY-MM-DD');
        endDate = moment().endOf('year').format('YYYY-MM-DD');
      }
      let faultRes = await useCustomFetch(`/api/v1/event/fault-trend`, 
        { method: Method.GET,  
          query:{
            cityCode: city, 
            sigunguCode: sigungu, 
            searchDateType: dateType, 
            startDate: startDate, 
            endDate: endDate} 
          });

      let result = faultRes.data.value as FaultTrendInfo;


      return result;
    },
    async tocDashboarEventData(eventType: string) {

			const eventRes = await useCustomFetch(`/api/v1/event/overview`, 
        { method: Method.GET,
          query:{eventType: eventType} });

			let result = eventRes.data.value as EventInfo[];

			return result;
		},
    async tocDashboardTrendData(cityCode: string, sigunguCode: string, searchDateType: number) {
      let city = this.defaultCity === '' ? cityCode : this.defaultCity
      let sigungu = this.defaultSigungu === '' ? sigunguCode : this.defaultSigungu
      let dateType = searchDateType ? searchDateType : 0;

      let startDate = moment().format('YYYY-MM-DD');
      let endDate = moment().format('YYYY-MM-DD');
      
      if(dateType === 0){
        startDate = moment().startOf('day').format('YYYY-MM-DD');
        endDate = moment().endOf('day').format('YYYY-MM-DD');
      } else if(dateType === 1){
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().endOf('month').format('YYYY-MM-DD');
      } else if(dateType === 2){
        startDate = moment().startOf('year').format('YYYY-MM-DD');
        endDate = moment().endOf('year').format('YYYY-MM-DD');
      }

			const trendRes = await useCustomFetch(`/api/v1/prod/trend/engsource`, 
        { method: Method.GET,  
          query:
          {
            cityCode: city, 
            sigunguCode: sigungu, 
            searchDateType: dateType, 
            startDate: startDate, 
            endDate: endDate
          } 
        });

			let result = trendRes.data.value as ProdTrendsInfo;

			return result;
		},

    async getZloc(cityCode: string, sigunguCode: string | undefined = undefined, dongCode: string | undefined = undefined){
      const result = await useCustomFetch(`/api/v1/common/zloc`, 
        { method: Method.GET, 
          query: {
            cityCode: cityCode, 
            sigunguCode: sigunguCode, 
            dongCode: dongCode
          }});
      return result.data.value;
    },

    async setRouteForm(fromPath:string, toPath: string, checkPath: string[]){
      if(!toPath) return;

      const userStore = useUserStore();
      const commonCodeStore = useCommonCodeStore();
      const engSourceMap = (commonCodeStore.getMapCodeList('engSource') as Map<string, CodeDto>);
      const engSourceList = engSourceMap.values();

      const routeList = this.tocRouteList;
      const userRoute = routeList[userStore.getUserType as keyof typeof routeList];

    
      let splitPath = toPath.split("/");

      let findMainRoute = userRoute.find(v => v.path.split('/').some(t => splitPath.includes(t)));
      
      if(findMainRoute){
        this.mainRoute = findMainRoute as selectRouteForm;
      }
    },

    async getTocEquipMapInfo(parameter: {
      cityCode?: string,
      sigunguCode?: string,
      dongCode?: string,
      prjKind?: string,
      buildingUsage?: string,
      prjRegYear?: string,
      engSource?: string,
      searchKind?: string,
      searchText?: string
    }){


      const result = await useCustomFetch(`/api/v1/monitor/map`, {method: Method.GET, query: {
        cityCode        : parameter.cityCode,
        sigunguCode     : parameter.sigunguCode,
        dongCode        : parameter.dongCode,
        prjKind         : parameter.prjKind,
        buildingUsage   : parameter.buildingUsage,
        prjRegYear      : parameter.prjRegYear,
        engSource       : parameter.engSource,
        searchKind      : parameter.searchKind,
        searchText      : parameter.searchText
      }});

      return result.data.value;

    },

    
    /**
    * 
    * @param pageNo // 조회 페이지 번호
    * @param pageSize // 조회 페이지수
    * @param cityCode // 시도
    * @param sigunguCode // 시군구
    * @param dongCode // 읍면동
    * @param mapLevel // 맵레벨
    * @param mapDongCode //지도상 현재 위치 법정동 코드
    * @param prjKind //사업구분코드
    * @param buildingUsage //건물용도
    * @param prjRegYear // 사업용도
    * @param engSource // 에너지원
    * @param searchKind // 검색 타입(설비번호 (10), 설치자명(20), 사이트명(30))
    * @param searchText // 검색어
    */
    async getTocEquipDataList(
      parameter: {
        pageNo:         number,
        pageSize:       number,
        cityCode:       string | undefined,
        sigunguCode:    string | undefined,
        dongCode:       string | undefined,
        equipStatus:    string | undefined,
        mapLevel:       number | undefined,
        mapDongCode:    string | undefined,
        prjKind:        string | undefined,
        buildingUsage:  string | undefined,
        prjRegYear:     string | undefined,
        engSource:      string | undefined,
        searchKind:     string | undefined,
        searchText:     string | undefined
      }
    ){
      const result = await useCustomFetch('/api/v1/equips', 
      {method: Method.GET, query: {
        pageNo          :parameter.pageNo, 
        pageSize        :parameter.pageSize, 
        cityCode        :parameter.cityCode,
        sigunguCode     :parameter.sigunguCode, 
        dongCode        :parameter.dongCode, 
        equipStatus     :parameter.equipStatus,
        mapLevel        :parameter.mapLevel, 
        mapDongCode     :parameter.mapDongCode, 
        prjKind         :parameter.prjKind, 
        buildingUsage   :parameter.buildingUsage, 
        prjRegYear      :parameter.prjRegYear, 
        engSource       :parameter.engSource, 
        searchKind      :parameter.searchKind, 
        searchText      :parameter.searchText
      }});


      return result.data.value;
    },

    async getEquipProdList(parameter: {
      pageNo: number,
      pageSize: number,
      engSource?: string;
      equipStatus?: string;
      cityCode?: string;
      sigunguCode?: string;
      dongCode?: string;
      prjKind?: string;
      prjRegYear?: string;
      searchKind?: string;
      searchText?: string;
      searchDateType?: number;
      startDate?: string;
      endDate?: string,
    }){

      const result = await useCustomFetch(`/api/v1/prod/list`, {
        method: Method.GET,
        query: {
          pageNo          : parameter.pageNo,
          pageSize        : parameter.pageSize,
          engSource       : parameter.engSource,
          equipStatus     : parameter.equipStatus,
          cityCode        : parameter.cityCode,
          sigunguCode     : parameter.sigunguCode,
          dongCode        : parameter.dongCode,
          prjKind         : parameter.prjKind,
          prjRegYear      : parameter.prjRegYear,
          searchKind      : parameter.searchKind,
          searchText      : parameter.searchText,
          searchDateType  : parameter.searchDateType,
          startDate       : parameter.startDate,
          endDate         : parameter.endDate
        }
      });

      return result.data.value;
    },


    async getEquipProdTrendList(parameter: {
      engSource?: string;
      equipStatus?: string;
      cityCode?: string;
      sigunguCode?: string;
      dongCode?: string;
      prjKind?: string;
      prjRegYear?: string;
      searchKind?: string;
      searchText?: string;
      searchDateType?: number;
      startDate?: string;
      endDate?: string,
    }){

      // let param = {};
      // param = parameter;
      // param.startDate = parameter.searchStartDateString;
      // param.endDate = parameter.searchEndDateString;

      const result = await useCustomFetch(`/api/v1/prod/trend/datetime`, {
        method: Method.GET, query: {
          engSource       : parameter.engSource,
          equipStatus     : parameter.equipStatus,
          cityCode        : parameter.cityCode,
          sigunguCode     : parameter.sigunguCode,
          dongCode        : parameter.dongCode,
          prjKind         : parameter.prjKind,
          prjRegYear      : parameter.prjRegYear,
          searchKind      : parameter.searchKind,
          searchText      : parameter.searchText,
          searchDateType  : parameter.searchDateType,
          startDate       : parameter.startDate,
          endDate         : parameter.endDate,
        }
      });

      return result.data.value;
    },
    async getSiteList(parameter: {
      pageNo          : number,
      pageSize        : number,
      cityCode?       : string,
      sigunguCode?    : string,
      dongCode?       : string,
      prjKind?        : string,
      buildingUsage?  : string,
      prjRegYear?     : string,
      engSource?      : string,
      searchKind?     : string,
      searchText?     : string
    }){
      const result = await useCustomFetch('/api/v1/sites', {method: Method.GET, query: {
        pageNo        :parameter.pageNo,
        pageSize      :parameter.pageSize,
        cityCode      :parameter.cityCode,
        sigunguCode   :parameter.sigunguCode,
        dongCode      :parameter.dongCode,
        prjKind       :parameter.prjKind,
        buildingUsage :parameter.buildingUsage,
        prjRegYear    :parameter.prjRegYear,
        engSource     :parameter.engSource,
        searchKind    :parameter.searchKind,
        searchText    :parameter.searchText
       }});

      return result.data.value
    },
    async getOwnerSiteInfo(ownerSeq: number, siteName: string){
      const result = await useCustomFetch(`/api/v1/site`, 
        {method: Method.GET, 
          query: {ownerSeq: ownerSeq, siteName: siteName}});

      return result.data.value;
    },
    
    async getSiteDetailInfo(ownerSeq: number, engSource: string, siteName: string){
      const result = await useCustomFetch(`/api/v1/prod/site`,
         {method: Method.GET, 
          query: {ownerSeq: ownerSeq, engSource: engSource, siteName: siteName}});

      return result.data.value;
    },

    async getSiteEquipProdInfo(ownerSeq: number, engSource: string, siteName: string, searchDateType: number, startDate: string, endDate: string){
      const result = await useCustomFetch(`/api/v1/prod/trend/equips`, 
        {method: Method.GET, 
          query: 
          {
            ownerSeq: ownerSeq, 
            engSource: engSource, 
            siteName: siteName, 
            searchDateType: searchDateType, 
            startDate: startDate, 
            endDate: endDate
          }});

      return result.data.value;
    },
    async getTocEventList(parameter: {
      pageNo: number,
      pageSize: number,
      searchDateType: number,
      startDate: string,
      endDate: string,
      eventType: string,
      eventProcessKind :number,
      cityCode?: string,
      sigunguCode?: string,
      dongCode?: string,
      prjKind?: string,
      buildingUsage?: string,
      prjRegYear?: string,
      engSource?: string,
      searchKind?: string,
      searchText?: string,
    }){
      const result = await useCustomFetch(`/api/v1/events`, {method: Method.GET, query: {
        pageNo          : parameter.pageNo,
        pageSize        : parameter.pageSize,
        searchDateType  : parameter.searchDateType,
        startDate       : parameter.startDate,
        endDate         : parameter.endDate,
        eventType       : parameter.eventType,
        eventProcessKind: parameter.eventProcessKind,
        cityCode        : parameter.cityCode,
        sigunguCode     : parameter.sigunguCode,
        dongCode        : parameter.dongCode,
        prjKind         : parameter.prjKind,
        buildingUsage   : parameter.buildingUsage,
        prjRegYear      : parameter.prjRegYear,
        engSource       : parameter.engSource,
        searchKind      : parameter.searchKind,
        searchText      : parameter.searchText,
      }});
  
      return result.data.value;
    },

    async getTocSearchCondi(parameter: {
      kind: string,
      regionKind: string,
      regionName?: string,
      regionCode?: string
    }){

      const result = await useCustomFetch('/api/v1/common/searchBarBaseInfo', {method: Method.GET, query: {
        kind        : parameter.kind,
        regionKind  : parameter.regionKind,
        regionName  : parameter.regionName,
        regionCode  : parameter.regionCode
      }});
      
      return result.data.value;
    },

    async getTocAnalysisOwner(parameter: {
      pageNo: number,
      pageSize: number,
      cityCode?: string,
      sigunguCode?: string,
      dongCode?: string,
      ownerName?: string
    }){

      if(parameter.ownerName?.trim() === '' || parameter.ownerName?.length < 2){
        parameter.ownerName = undefined
      }

      const result = await useCustomFetch(`/api/v1/owners`, {method: Method.GET, query: {
        pageNo      : parameter.pageNo,
        pageSize    : parameter.pageSize,
        cityCode    : parameter.cityCode,
        sigunguCode : parameter.sigunguCode,
        dongCode    : parameter.dongCode,
        ownerName   : parameter.ownerName
      }});

      return result.data.value;
    },

    async getTocAnalysisOwnerEquip(ownerName: string){
      // const result = await useCustomFetch(`/api/v1/owners/${ownerSeq}`);
      const result = await useCustomFetch(`/api/v1/equips`, {method: Method.GET, query: {
        pageNo: 1,
        pageSize: 50,
        searchKind: '20',
        searchText: ownerName
      }});

      return result.data.value.content;
    },

    async getTocAnalysisOwnerEquipBySeq(ownerSeq: number){
      const result = await useCustomFetch(`/api/v1/m/equips`, {method: Method.GET, query: {
        ownerSeq: ownerSeq
      }});

      return result.data.value;
    },


    async getTocAnalysisOwnerEquipData(parameter: {
      searchDateType: number,
      startDate: string,
      endDate: string,
      cid: string
    }){

      const result = await useCustomFetch(`/api/v1/prod/info`, {method: Method.GET, query: {
        searchDateType  : parameter.searchDateType,
        startDate       : parameter.startDate,
        endDate         : parameter.endDate,
        cid             : parameter.cid
      }});

      return result.data.value;
    },
    async getTocCompareOwnerData(parameter: {
      pageNo      : number,
      pageSize    : number,
      engSource   : string,
      cityCode    : string,
      sigunguCode : string,
      dongCode    : string,
      searchDate  : string
    }){
      const result = await useCustomFetch(`/api/v1/toc/analysis/compare/owner`, {method: Method.GET, query:{
        pageNo      : parameter.pageNo,
        pageSize    : parameter.pageSize,
        engSource   : parameter.engSource,
        cityCode    : parameter.cityCode,
        sigunguCode : parameter.sigunguCode,
        dongCode    : parameter.dongCode,
        searchDate  : parameter.searchDate
      }});

      return result.data.value;
    },

    async getTocCompareOwnerPageData(parameter: {
      pageNo      : number,
      pageSize    : number,
      engSource   : string,
      cityCode    : string,
      sigunguCode : string,
      dongCode    : string,
      searchDate  : string
    }){
      const result = await useCustomFetch(`/api/v1/toc/analysis/compare/owner/page`, {method: Method.GET, query:{
        pageNo      : parameter.pageNo,
        pageSize    : parameter.pageSize,
        engSource   : parameter.engSource,
        cityCode    : parameter.cityCode,
        sigunguCode : parameter.sigunguCode,
        dongCode    : parameter.dongCode,
        searchDate  : parameter.searchDate
      }});

      return result.data.value;
    },
    async getTocCompareRegionData(parameter: {
      engSource   : string,
      cityCode    : string,
      sigunguCode : string,
      searchDate  : string
    }, sort: string | undefined = undefined){
      const result = await useCustomFetch(`/api/v1/toc/analysis/compare/area`, {method: Method.GET, query: {
        engSource     : parameter.engSource,
        cityCode      : parameter.cityCode,
        sigunguCode   : parameter.sigunguCode,
        searchDate    : parameter.searchDate
      }});

      if(sort === 'down')     result.data.value.sort((a,b) => b.prodHour - a.prodHour);
      else if(sort === 'up')  result.data.value.sort((a,b) => a.prodHour - b.prodHour)
      return result.data.value;
    },

    async getTocNoticeData(){
      const result = await useCustomFetch('/api/v1/boards/notices',
         {method: Method.GET, 
          query: {sysKind: 'pc', size: 100}});

      return result.data.value;
    },
    async getTocNoticeDetailData(noticeSeq: number){
      const result = await useCustomFetch(`/api/v1/boards/notices/r/${noticeSeq}`, {method: Method.GET});

      return result.data.value;
    },
    async getTocQnaData(userSeq: number){
      const result = await useCustomFetch(`/api/v1/boards/qnas`,
         {method: Method.GET, 
          query: {sysKind:'pc', size: '100', userSeq: userSeq}});
      // const result = await useCustomFetch(`/api/v1/boards/qnas`, {method: Method.GET, query: {sysKind:'pc', size: '100', userSeq: userSeq}});

      return result.data.value;
    },
    async getTocQnaDetailData(qnaSeq: number){
      const result = await useCustomFetch(`/api/v1/boards/qnas/${qnaSeq}`, {method: Method.GET});

      return result.data.value;
    },
    async setTocQna(qnaFormData: FormData){
      const res = await useCustomFetch('/api/v1/boards/qnas/regist', {
          method: Method.POST,
          body: qnaFormData,
      })
      return res;
    },
    async setTocQnaAnswer(qnaSeq: number, answerData: any){
      const res = await useCustomFetch(`/api/v1/boards/qnas/${qnaSeq}`, {method: Method.PUT, body: answerData});

      return res;
    },
    async getAttachment(attachSeq: number){
      const res = await useCustomFetch(`/api/v1/boards/qnas/attach`, 
        {method: Method.GET, query: {attachSeq: attachSeq}});
      
      return res.data.value;
    },
    async getManagerInfos(userSeq: number){
      const res = await useCustomFetch('/api/v1/setting/manager',
         {method: Method.GET, query:{userSeq: userSeq}});

      return res.data.value;
    },
    async createManagerInfo(parameter: {
      accountStatus:    string,
      area:             string,
      areaKind:         string,
      authGroupSeq:     string,
      companyKind:      string,
      companySeq:       string,
      email?:           string,
      isAdmin:          string,
      mobile?:          string,
      phone?:           string,
      regId?:           number,
      userId?:          string,
      userName?:        string,
      userPassword:     string,
    }){

      const res = await useCustomFetch('/api/v1/setting/manager', {method:Method.POST, body: {
        accountStatus:    parameter.accountStatus,
        area:             parameter.area,
        areaKind:         parameter.areaKind,
        authGroupSeq:     parameter.authGroupSeq,
        companyKind:      parameter.companyKind,
        companySeq:       parameter.companySeq,
        email :           parameter.email,
        isAdmin:          parameter.isAdmin,
        mobile :          parameter.mobile,
        phone :           parameter.phone,
        regId :           parameter.regId,
        userId :          parameter.userId,
        userName :        parameter.userName,
        userPassword:     parameter.userPassword,
      }});

      return res.data.value;

    },
    async changeManagerInfo(parameter: {
      accountStatus: string,
      area: string,
      areaKind: string,
      email: string,
      isAdmin: string,
      mobile: string,
      phone: string,
      updId: number,
      userName: string,
      updCompanyManagerSeq: number,
      userSeq: number,
      companyKind: string,
      userPassword?: string
    }, companyManagerSeq: number){
      

      const res = await useCustomFetch(`/api/v1/setting/manager/${companyManagerSeq}`, {method: Method.PUT, body: {
        accountStatus:          parameter.accountStatus,
        area:                   parameter.area,
        areaKind:               parameter.areaKind,
        email:                  parameter.email,
        isAdmin:                parameter.isAdmin,
        mobile:                 parameter.mobile,
        phone:                  parameter.phone,
        updId:                  parameter.updId,
        userName:               parameter.userName,
        updCompanyManagerSeq:   companyManagerSeq,
        userSeq:                parameter.userSeq,
        companyKind:            parameter.companyKind,
        userPassword:           parameter.userPassword
      }});

      return  res.data.value;
    },
    async changeUserKinds(updKind: string, updId: number, userSeqList: Number[], companyKind: string | undefined, companyManagerSeq: number| undefined){
      const res = await useCustomFetch(`/api/v1/setting/manager`, {method:Method.PUT, body: {
        updKind: updKind, 
        updId: updId, 
        userSeqList: userSeqList, 
        companyKind: companyKind, 
        updCompanyManagerSeq: companyManagerSeq
      }});

      return res.data.value;
    },
    async getSetEquipManager(parameter: {
      userSeq     : number,
      pageNo      : number,
      pageSize    : number,
      engSource?  : string,
      cityCode?   : string,
      sigunguCode?  : string,
      dongCode?     : string,
      prjKind?      : string,
      prjRegYear?   : string,
      searchKind?   : string,
      searchText?   : string
    }){
      const res = await useCustomFetch(`/api/v1/setting/manager/equip`, {method: Method.GET, query: {
        userSeq       : parameter.userSeq,
        pageNo        : parameter.pageNo,
        pageSize      : parameter.pageSize,
        engSource     : parameter.engSource,
        cityCode      : parameter.cityCode,
        sigunguCode   : parameter.sigunguCode,
        dongCode      : parameter.dongCode,
        prjKind       : parameter.prjKind,
        prjRegYear    : parameter.prjRegYear,
        searchKind    : parameter.searchKind,
        searchText    : parameter.searchText
      }});

      return res.data.value;
    },
    async confirmAllEquipCompanyManager(setKind: 1 | 2,
      parameter: {
      userSeq             : number,
      engSource?          : string,
      cityCode?           : string,
      sigunguCode?        : string,
      dongCode?           : string,
      prjKind?            : string,
      prjRegYear?         : string,
      searchKind?         : string,
      searchText?         : string,
      companyKind?        : string,
      companyManagerSeq?  : string,
    }){
      const res = await useCustomFetch(`/api/v1/setting/manager/equip/${setKind}`, {method: Method.PUT, query: {
        userSeq            :  parameter.userSeq,
        engSource          : parameter.engSource,
        cityCode           : parameter.cityCode,
        sigunguCode        : parameter.sigunguCode,
        dongCode           : parameter.dongCode,
        prjKind            : parameter.prjKind,
        prjRegYear         : parameter.prjRegYear,
        searchKind         : parameter.searchKind,
        searchText         : parameter.searchText,
        companyKind        : parameter.companyKind,
        companyManagerSeq  : parameter.companyManagerSeq,
      }});

      return res.data.value;
    },
    async confirmSelectEquipCompanyManager(
      setKind: 1|2,
      companyKind: string,
      companyManagerSeq: number,
      updId: number,
      cidList: string[]
    ){
      const res = await useCustomFetch(`/api/v1/setting/manager/equip/${setKind}`, {method: Method.PUT, body: {
        companyKind: companyKind, 
        companyManagerSeq: companyManagerSeq, 
        updId: updId, 
        cidList: cidList
      }});

      return res.data.value;
    },
    async setUserTocSetting(data: any){
      const res = await useCustomFetch(`/api/v1/setting/personalization`, 
        {method: Method.POST, body: data});

      return res.data.value;
    },
    async getUserTocSettingImage(url: string){
      const res = await useCustomFetch(`/api/v1/setting/personalization/attach`, 
        {method: Method.GET, query: {filePath: url}});

      return res.data.value;
    },
    async getTocOwnerList(parameter: {
      pageNo       : number,
      pageSize     : number,
      cityCode?    : string,
      sigunguCode? : string,
      dongCode?    : string,
      searchKind?  : string,
      searchText?  : string
    }){

      const res = await useCustomFetch(`/api/v1/owners`, {method: Method.GET, query:{
          pageNo        : parameter.pageNo,
          pageSize      : parameter.pageSize,
          cityCode      : parameter.cityCode,
          sigunguCode   : parameter.sigunguCode,
          dongCode      : parameter.dongCode,
          // searchKind    : parameter.searchKind,
          ownerName     : parameter.searchText
      }});

      return res.data.value;
    },
    async getTocExcelOwnerList(parameter: {
      pageNo         : number,
      pageSize       : number,
      cityCode?      : string,
      sigunguCode?   : string,
      dongCode?      : string,
      accountStatus? : string,
      searchKind?    : string,
      searchText?    : string
    }){

      const res = await useCustomFetch(`/api/v1/system/owners`, {method: Method.GET, query:{
          pageNo        : parameter.pageNo,
          pageSize      : parameter.pageSize,
          cityCode      : parameter.cityCode,
          sigunguCode   : parameter.sigunguCode,
          dongCode      : parameter.dongCode,
          accountStatus : parameter.accountStatus,
          searchKind    : parameter.searchKind,
          searchText    : parameter.searchText
        }});

      return res.data.value;
    },
    async getTocExcelDownload(type: string){
      const res = await useCustomFetch(`/api/v1/system/excelsample`, 
        {method: Method.GET, query: {kind: type}});

      if(res.data.value){
        return res.data.value;
      } else {
        return res.error.value;
      }
    },
    async getTocEquipExcelDownload(type: string, engSource: string){
      const res = await useCustomFetch(`/api/v1/system/excel`, 
        {method: Method.GET, 
          query: {kind: type, engSource: engSource}});

      console.log(res);
      if(res.data.value){
        return res.data.value;
      } else {
        return res.error.value;
      }
    },
    async putTocExcel(type: string, files: any){
      let form = new FormData();

      let types = {
        kind: type
      }

      form.append('contents', JSON.stringify(types));
      form.append('files', files);

      const res = await useCustomFetch(`/api/v1/system/excel`, {method: Method.POST, body: form});

      return res;
    },
    async getASList(){
      /** api  호출 먼저 */

      const dummy = ASTempData.ASList;
      this.ASList = dummy;


      //아래 임시
      // for(let i = 0; i < 5; i++){
      //   this.ASList?.push({
      //     idx             : i+1,
      //     cid            : '10017042437',
      //     siteName       : '최종철 (사석1길)',
      //     engSource      : '10',
      //     engSourceName  : '태양광',
      //     equipName      : '사석1길 23-2',
      //     equipAddr      : '충청북도 진천군 진천읍',
      //     equipDetailAddr : `사석1길 23-2`,
      //     dongCode         : '123412341234',
      //     prjKind          : '33001',
      //     prjRegYear       : '2024',
      //     ownerSeq         : 556699,
      //     equipStatus      :  'normal',
      //     equipCompSeq     : '1',
      //     ownerName        : '최종철 (사석1길)',
      //     ownerMobile     : '111-1111-111',
      //     cityCode        : '23',
      //     sigunguCode     : '23450',
      //     companyName       : '동호엔지니어링',
      //     constructionDate  : '2024-01-01',
      //     equipCapacity     : '3',
      //     installPlanNumber   : '20240110101',
      //     installConfirmMngNumber : '11223344'

      //   })
      // }
    },
    async selectAS(cid: string){
      this.selectASInfo = ASTempData.ASRequestDetail.find(v => v.cid === cid) as ASList;
    },
    async getASActionList(){
      const dummy = ASTempData.ASActionList;
      this.ASActionList = dummy;

      this.ASActionList = this.ASActionList.sort((a,b) => {
        if(a.asRequestFormID < b.asRequestFormID){
          return 1;
        } 

        if(a.asRequestFormID > b.asRequestFormID){
          return -1;
        }

        return 0;
      })
    },
    async selectASAction(requestID: number){
      const dummy = ASTempData.ASActionDetailList;
      this.selectASActionDetail = dummy.find(v => v.asRequestFormID === requestID);
    }
  },
})